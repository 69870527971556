
























































import { Component, Vue, PropSync, Watch, Prop } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { UtilsPublicResources } from "@/utils/utils-public-resources";
@Component({
  components: { CompHeader: () => import("./CompHeaderLabel.vue") }
})
export default class CompSexo extends Vue {
  @PropSync("sexo", { type: String }) syncedsexo!: string;
  @Prop() index!: number;
  public selected: number = 0;
  @Watch("selected")
  public Wselected() {
    if (this.selected === 0) {
      this.syncedsexo = "Hombre";
    } else {
      this.syncedsexo = "Mujer";
    }
  }

  public created() {
    if ((this.syncedsexo = "Hombre")) {
      this.selected = 0;
    } else {
      this.selected = 1;
    }
    if (UtilsString.IsNullOrWhiteSpace(this.syncedsexo)) {
      this.syncedsexo = "Hombre";
    }
    this.$emit("validado", { index: this.index, valido: true });
  }
  public getimagen(imagen: string) {
    return UtilsPublicResources.getImageFromImages(imagen);
  }
}
